import c1 from "../images/КарточкиТовара/1.webp";
import c2 from "../images/КарточкиТовара/2.webp";
import c3 from "../images/КарточкиТовара/3.webp";
import c4 from "../images/КарточкиТовара/4.webp";
import c5 from '../images/КарточкиТовара/5.webp';
import c6 from "../images/КарточкиТовара/6.webp";
import c7 from "../images/КарточкиТовара/7.webp";
import c8 from '../images/КарточкиТовара/8.webp';
import c9 from '../images/КарточкиТовара/9.webp';
import c10 from '../images/КарточкиТовара/10.webp';
import c11 from "../images/КарточкиТовара/11.webp";
import c12 from '../images/КарточкиТовара/12.webp';
import c13 from '../images/КарточкиТовара/13.webp';
import c14 from '../images/КарточкиТовара/14.webp';
import c15 from "../images/КарточкиТовара/15.webp";
import c16 from '../images/КарточкиТовара/16.webp';
import c17 from '../images/КарточкиТовара/17.webp';
import c18 from "../images/КарточкиТовара/18.webp";
import c19 from "../images/КарточкиТовара/19.webp";
import c20 from '../images/КарточкиТовара/20.webp';
import c21 from "../images/КарточкиТовара/21.webp";
import c22 from '../images/КарточкиТовара/22.webp';
import c23 from "../images/КарточкиТовара/23.webp";
import c24 from "../images/КарточкиТовара/24.webp";
import c25 from "../images/КарточкиТовара/25.webp";
import c26 from "../images/КарточкиТовара/26.webp";
import c27 from "../images/КарточкиТовара/27.webp";
import c29 from '../images/КарточкиТовара/29.webp'
import c30 from '../images/КарточкиТовара/30.webp'
import c31 from '../images/КарточкиТовара/31.webp'
import c32 from '../images/КарточкиТовара/32.webp'
import c33 from '../images/КарточкиТовара/33.webp'
import c34 from '../images/КарточкиТовара/34.webp'
import c35 from '../images/КарточкиТовара/35.webp'
import c36 from '../images/КарточкиТовара/36.webp'
import c37  from '../images/КарточкиТовара/37.webp'
import c38  from '../images/КарточкиТовара/38.webp'
import c39  from '../images/КарточкиТовара/39.webp'
import c40  from '../images/КарточкиТовара/40.webp'
import c41  from '../images/КарточкиТовара/41.webp'
import c42  from '../images/КарточкиТовара/42.webp'
import c43  from '../images/КарточкиТовара/43.webp'
import c44  from '../images/КарточкиТовара/44.webp'
import c45  from '../images/КарточкиТовара/45.webp'
import c46  from '../images/КарточкиТовара/46.webp'
import c47  from '../images/КарточкиТовара/47.webp'
import c48 from '../images/КарточкиТовара/48.webp'
import c49  from '../images/КарточкиТовара/49.webp'
import c50  from '../images/КарточкиТовара/50.webp'
import c51  from '../images/КарточкиТовара/51.webp'
import c52  from '../images/КарточкиТовара/52.webp'
import c53  from '../images/КарточкиТовара/53.webp'
import c54  from '../images/КарточкиТовара/54.webp'
import c55  from '../images/КарточкиТовара/55.webp'
import c56  from '../images/КарточкиТовара/56.webp'
import c57  from '../images/КарточкиТовара/57.webp'
import c58  from '../images/КарточкиТовара/58.webp'
import c59  from '../images/КарточкиТовара/59.webp'
import c60  from '../images/КарточкиТовара/60.webp'
import c61  from '../images/КарточкиТовара/61.webp'
import c62  from '../images/КарточкиТовара/62.webp'
import c63  from '../images/КарточкиТовара/63.webp'
import c64  from '../images/КарточкиТовара/64.webp'
import c65  from '../images/КарточкиТовара/65.webp'
import c66  from '../images/КарточкиТовара/66.webp'
import c67  from '../images/КарточкиТовара/67.webp'
import c68 from '../images/КарточкиТовара/68.webp'
import c69 from '../images/КарточкиТовара/69.webp'
import c70 from '../images/КарточкиТовара/70.webp'
import c71 from '../images/КарточкиТовара/71.webp'
import c72 from '../images/КарточкиТовара/72.webp'
import c73 from '../images/КарточкиТовара/73.webp'
import c74 from '../images/КарточкиТовара/74.webp'
import c75 from '../images/КарточкиТовара/75.webp'
import c76 from '../images/КарточкиТовара/76.webp'
import c77 from '../images/КарточкиТовара/77.webp'
import c78 from '../images/КарточкиТовара/78.webp'
import c79 from '../images/КарточкиТовара/79.webp'
import c80 from '../images/КарточкиТовара/80.webp'
import c81 from '../images/КарточкиТовара/81.webp'
import c82 from '../images/КарточкиТовара/82.webp'
import c83 from '../images/КарточкиТовара/83.webp'
import c84 from '../images/КарточкиТовара/84.webp'
import c85 from '../images/КарточкиТовара/85.webp'
import c86 from '../images/КарточкиТовара/86.webp'
import c87 from '../images/КарточкиТовара/87.webp'
import c88 from '../images/КарточкиТовара/88.webp'
import c89 from '../images/КарточкиТовара/89.webp'
import c90 from '../images/КарточкиТовара/90.webp'
import c91 from '../images/КарточкиТовара/91.webp'
import c92 from '../images/КарточкиТовара/92.webp'
import c93 from '../images/КарточкиТовара/93.webp'
import c94 from '../images/КарточкиТовара/94.webp'
import c95 from '../images/КарточкиТовара/95.webp';
import c96 from "../images/КарточкиТовара/96.webp";
import c97 from "../images/КарточкиТовара/97.webp";
import c98 from "../images/КарточкиТовара/98.webp";
import c99 from "../images/КарточкиТовара/99.webp";
import c100 from "../images/КарточкиТовара/100.webp";
import c101 from "../images/КарточкиТовара/101.webp";
import c102 from "../images/КарточкиТовара/102.webp";
import c103 from "../images/КарточкиТовара/103.webp";

import promo1 from '../images/promo/promo1.webp'
import promo2 from '../images/promo/promo2.webp'
import promo3 from '../images/promo/promo3.webp'
import promo4 from '../images/promo/promo4.webp'
import promo5 from '../images/promo/promo5.webp'
import promo6 from '../images/promo/promo6.webp'
import promo7 from '../images/promo/promo7.webp'
import promo8 from '../images/promo/promo8.webp'
import promo9 from '../images/promo/promo9.webp'
import promo10  from '../images/promo/promo10.webp'
import promo11  from '../images/promo/promo11.webp'

const cardRoad = [
  {
    id: 1,
    url: c1,
  },
  {
    id: 57,
    url: c57
  },
  {
    id: 96,
    url: c96
  },
  {
    id: 97,
    url: c97
  },
  {
    id: 98,
    url: c98
  },
  {
    id: 99,
    url: c99
  },
  {
    id: 100,
    url: c100
  },
  {
    id: 101,
    url: c101
  },
  {
    id: 102,
    url: c102
  },
  {
    id: 103,
    url: c103
  },
  {
    id: 48,
    url: c48
  },
  {
    id: 2,
    url: c2
  },
  {
    id: 115,
    url: promo1
  },
  {
    id: 3,
    url: c3
  },
  {
    id: 4,
    url: c4
  },
  {
    id: 5,
    url: c5
  },
  {
    id: 100,
    url: promo2
  },
  {
    id: 6,
    url: c6
  },
  {
    id: 7,
    url: c7
  },
  {
    id: 8,
    url: c8
  },
  {
    id: 101 ,
    url: promo3
  },
  {
    id: 9,
    url: c9
  },
  {
    id: 10,
    url: c10
  },
  {
    id: 11,
    url: c11
  },
  {
    id: 102 ,
    url: promo4
  },
  {
    id: 12,
    url: c12
  },
  {
    id: 13,
    url: c13
  },
  {
    id: 14,
    url: c14
  },
  {
    id: 103 ,
    url: promo5
  },
  {
    id: 15,
    url: c15
  },
  {
    id: 16,
    url: c16
  },
  {
    id: 17,
    url: c17
  },
  {
    id: 104 ,
    url: promo6
  },
  {
    id: 18,
    url: c18
  },
  {
    id: 19,
    url: c19
  },
  {
    id: 20,
    url: c20
  },
  {
    id: 105 ,
    url: promo7
  },
  {
    id: 21,
    url: c21
  },
  {
    id: 22,
    url: c22
  },
  {
    id: 23,
    url: c23
  },
  {
    id: 106 ,
    url: promo8
  },
  {
    id: 24,
    url: c24
  },
  {
    id: 25,
    url: c25
  },
  {
    id: 26,
    url: c26
  },
  {
    id: 107 ,
    url: promo9
  },
  {
    id: 27,
    url: c27
  },
  {
    id: 29,
    url: c29
  },
  {
    id: 30,
    url: c30
  },
  {
    id: 108,
    url: promo10
  },
  {
    id: 31,
    url: c31
  },
  {
    id: 32,
    url: c32
  },
  {
    id: 33,
    url: c33
  },
  {
    id: 109 ,
    url: promo11
  },
  {
    id: 34,
    url: c34
  },
  {
    id: 35,
    url: c35
  },
  {
    id: 36,
    url: c36
  },
  {
    id: 110 ,
    url: promo1
  },
  {
    id: 37,
    url: c37
  },
  {
    id: 38,
    url: c38
  },
  {
    id: 39,
    url: c39
  },
  {
    id: 111 ,
    url: promo2
  },
  {
    id: 40,
    url: c40
  },
  {
    id: 41,
    url: c41
  },
  {
    id: 42,
    url: c42
  },
  {
    id: 112 ,
    url: promo3
  },
  {
    id: 43,
    url: c43
  },
  {
    id: 44,
    url: c44
  },
  {
    id: 45,
    url: c45
  },
  {
    id: 114 ,
    url: promo5
  },
  {
    id: 46,
    url: c46
  },
  {
    id: 47,
    url: c47
  },
  {
    id: 49,
    url: c49
  },
  {
    id: 118 ,
    url: promo6
  },
  // {
  //   id: 50,
  //   url: c50
  // },
  // {
  //   id: 51,
  //   url: c51
  // },
  {
    id: 52,
    url: c52
  },
  {
    id: 116 ,
    url: promo7
  },
  // {
  //   id: 53,
  //   url: c53
  // },
  {
    id: 54,
    url: c54
  },
  {
    id: 55,
    url: c55
  },
  {
    id: 117 ,
    url: promo8
  },
  // {
  //   id: 56,
  //   url: c56
  // },
  {
    id: 58,
    url: c58
  },
  {
    id: 59,
    url: c59
  },
  {
    id: 60,
    url: c60
  },
  {
    id: 61,
    url: c61,
  },
  {
    id: 62,
    url: c62,
  },
  {
    id: 63,
    url: c63,
  },
  {
    id: 64,
    url: c64,
  },
  {
    id: 65,
    url: c65,
  },
  {
    id: 66,
    url: c66,
  },
  {
    id: 67,
    url: c67,
  },
  {
    id: 68,
    url: c68,
  },
  {
    id: 69,
    url: c69,
  },
  {
    id: 70,
    url: c71,
  },
  {
    id: 72,
    url: c72,
  },
  {
    id: 73,
    url: c73,
  },
  {
    id: 74,
    url: c74,
  },
  {
    id: 75,
    url: c75,
  },
  {
    id: 76,
    url: c76,
  },
  {
    id: 77,
    url: c77,
  },
  {
    id: 78,
    url: c78,
  },
  {
    id: 79,
    url: c79,
  },
  {
    id: 80,
    url: c80,
  },
  {
    id: 81,
    url: c81,
  },
  {
    id: 82,
    url: c82,
  },
  {
    id: 83,
    url: c83,
  },
  {
    id: 84,
    url: c84,
  },
  {
    id: 85,
    url: c85,
  },
  {
    id: 86,
    url: c86,
  },
  {
    id: 87,
    url: c87,
  },
  {
    id: 88,
    url: c88,
  },
  {
    id: 89,
    url: c89,
  },
  {
    id: 90,
    url: c90,
  },
  {
    id: 91,
    url: c91,
  },
  {
    id: 92,
    url: c92,
  },
  {
    id: 93,
    url: c93,
  },
  {
    id: 94,
    url: c94,
  },
  {
    id: 95,
    url: c95,
  }
]

export default cardRoad