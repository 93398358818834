import c1 from "../images/КарточкиТовара/1.webp";
import c2 from "../images/КарточкиТовара/2.webp";
import c3 from "../images/КарточкиТовара/3.webp";
import c4 from "../images/КарточкиТовара/4.webp";
import c5 from "../images/КарточкиТовара/5.webp";
import c6 from "../images/КарточкиТовара/6.webp";
import c7 from "../images/КарточкиТовара/7.webp";
import c8 from "../images/КарточкиТовара/8.webp";
import c9 from "../images/КарточкиТовара/9.webp";
import c10 from "../images/КарточкиТовара/10.webp";
import c11 from "../images/КарточкиТовара/11.webp";
import c12 from "../images/КарточкиТовара/12.webp";
import c13 from "../images/КарточкиТовара/13.webp";
import c14 from "../images/КарточкиТовара/14.webp";
import c15 from "../images/КарточкиТовара/15.webp";
import c16 from "../images/КарточкиТовара/16.webp";
import c17 from "../images/КарточкиТовара/17.webp";
import c18 from "../images/КарточкиТовара/18.webp";
import c19 from "../images/КарточкиТовара/19.webp";
import c20 from "../images/КарточкиТовара/20.webp";
import c21 from "../images/КарточкиТовара/21.webp";
import c22 from "../images/КарточкиТовара/22.webp";
import c23 from "../images/КарточкиТовара/23.webp";
import c24 from "../images/КарточкиТовара/24.webp";
import c25 from "../images/КарточкиТовара/25.webp";
import c26 from "../images/КарточкиТовара/26.webp";
import c27 from "../images/КарточкиТовара/27.webp";
import c29 from "../images/КарточкиТовара/29.webp";
import c30 from "../images/КарточкиТовара/30.webp";
import c31 from "../images/КарточкиТовара/31.webp";
import c32 from "../images/КарточкиТовара/32.webp";
import c33 from "../images/КарточкиТовара/33.webp";
import c34 from "../images/КарточкиТовара/34.webp";
import c35 from "../images/КарточкиТовара/35.webp";
import c36 from "../images/КарточкиТовара/36.webp";
import c37 from "../images/КарточкиТовара/37.webp";
import c38 from "../images/КарточкиТовара/38.webp";
import c39 from "../images/КарточкиТовара/39.webp";
import c40 from "../images/КарточкиТовара/40.webp";
import c41 from "../images/КарточкиТовара/41.webp";
import c42 from "../images/КарточкиТовара/42.webp";
import c43 from "../images/КарточкиТовара/43.webp";
import c44 from "../images/КарточкиТовара/44.webp";
import c45 from "../images/КарточкиТовара/45.webp";
import c46 from "../images/КарточкиТовара/46.webp";
import c47 from "../images/КарточкиТовара/47.webp";
import c48 from "../images/КарточкиТовара/48.webp";
import c49 from "../images/КарточкиТовара/49.webp";
import c50 from "../images/КарточкиТовара/50.webp";
import c51 from "../images/КарточкиТовара/51.webp";
import c52 from "../images/КарточкиТовара/52.webp";
import c53 from "../images/КарточкиТовара/53.webp";
import c54 from "../images/КарточкиТовара/54.webp";
import c55 from "../images/КарточкиТовара/55.webp";
import c56 from "../images/КарточкиТовара/56.webp";
import c57 from "../images/КарточкиТовара/57.webp";
import c58 from "../images/КарточкиТовара/58.webp";
import c59 from "../images/КарточкиТовара/59.webp";
import c60 from "../images/КарточкиТовара/60.webp";
import c61 from "../images/КарточкиТовара/61.webp";
import c62 from "../images/КарточкиТовара/62.webp";
import c63 from "../images/КарточкиТовара/63.webp";
import c64 from "../images/КарточкиТовара/64.webp";
import c65 from "../images/КарточкиТовара/65.webp";
import c66 from "../images/КарточкиТовара/66.webp";
import c67 from "../images/КарточкиТовара/67.webp";
import c68 from "../images/КарточкиТовара/68.webp";
import c69 from "../images/КарточкиТовара/69.webp";
import c70 from "../images/КарточкиТовара/70.webp";
import c71 from "../images/КарточкиТовара/71.webp";
import c72 from "../images/КарточкиТовара/72.webp";
import c73 from "../images/КарточкиТовара/73.webp";
import c74 from "../images/КарточкиТовара/74.webp";
import c75 from "../images/КарточкиТовара/75.webp";
import c76 from "../images/КарточкиТовара/76.webp";
import c77 from "../images/КарточкиТовара/77.webp";
import c78 from "../images/КарточкиТовара/78.webp";
import c79 from "../images/КарточкиТовара/79.webp";
import c80 from "../images/КарточкиТовара/80.webp";
import c81 from "../images/КарточкиТовара/81.webp";
import c82 from "../images/КарточкиТовара/82.webp";
import c83 from "../images/КарточкиТовара/83.webp";
import c84 from "../images/КарточкиТовара/84.webp";
import c85 from "../images/КарточкиТовара/85.webp";
import c86 from "../images/КарточкиТовара/86.webp";
import c87 from "../images/КарточкиТовара/87.webp";
import c88 from "../images/КарточкиТовара/88.webp";
import c89 from "../images/КарточкиТовара/89.webp";
import c90 from "../images/КарточкиТовара/90.webp";
import c91 from "../images/КарточкиТовара/91.webp";
import c92 from "../images/КарточкиТовара/92.webp";
import c93 from "../images/КарточкиТовара/93.webp";
import c94 from "../images/КарточкиТовара/94.webp";
import c95 from "../images/КарточкиТовара/95.webp";
import c96 from "../images/КарточкиТовара/96.webp";
import c97 from "../images/КарточкиТовара/97.webp";
import c98 from "../images/КарточкиТовара/98.webp";
import c99 from "../images/КарточкиТовара/99.webp";
import c100 from "../images/КарточкиТовара/100.webp";
import c101 from "../images/КарточкиТовара/101.webp";
import c102 from "../images/КарточкиТовара/102.webp";
import c103 from "../images/КарточкиТовара/103.webp";

const card = [
  {
    id: 101,
    url: [
      {
        id: 1,
        url: c101,
      },
      {
        id: 2,
        url: c102,
      },
      {
        id: 2,
        url: c103,
      },
    ],
    price: 900,
    title: "Шоколадно-банановый кекс",
    type: "bakery",
    numberOfPieces: "1 шт.",
    description:
      "Шоколадно-банановый кекс, приготовленный из бельгийского темного шоколада, представляет собой изысканное сочетание сладости и аромата. Этот десерт обладает насыщенным шоколадным вкусом, который дополняется лёгкой ноткой тропического банана. Кекс имеет мягкую и воздушную текстуру.",
  },
  {
    id: 98,
    url: [
      {
        id: 1,
        url: c98,
      },
      {
        id: 2,
        url: c99,
      },
      {
        id: 3,
        url: c100,
      },
    ],
    price: 2500,
    title: "Киш из красной рыбы",
    type: "bakery",
    numberOfPieces: "1 шт.",
    description:
      "Киш из красной рыбы — это изысканный открытый пирог, который станет прекрасным угощением для любителей морепродуктов. Основа пирога сделана из нежного теста, а начинка состоит из сочной красной рыбы. Этот пирог станет идеальным дополнением к вашему столу и подарит незабываемые вкусовые ощущения.",
  },
  {
    id: 96,
    url: [
      {
        id: 1,
        url: c96,
      },
      {
        id: 2,
        url: c97,
      },
    ],
    price: 6000,
    title: "№-017",
    type: "bakery",
    numberOfPieces: "1 шт.",
    description:
      "Прекрасный торт порадует вас и ваших гостей удивительным вкусом и нежностью.",
  },
  {
    id: 94,
    url: [
      {
        id: 1,
        url: c94,
      },
      {
        id: 2,
        url: c95,
      },
    ],
    price: 1500,
    title: "Тыквенный кекс",
    type: "bakery",
    numberOfPieces: "1 шт.",
    description:
      "Тыквенный кекс с клюквой, апельсином, грецким орехом, изюмом, крем чизом, и цельнозерновой муки – это настоящий осенний десерт, который обязательно поднимет настроение в холодную погоду. Сочный и ароматный кекс идеально сочетает в себе сладость тыквы, легкую кислинку клюквы и свежесть апельсина. Грецкий орех и изюм добавляют текстуры и богатства вкуса. Этот тыквенный кекс станет отличным выбором как для уютного семейного чаепития, так и для праздничного стола. Подавайте его с чашкой горячего какао или ароматного чая, и наслаждайтесь каждым кусочком этого восхитительного десерта!",
  },
  {
    id: 91,
    url: [
      {
        id: 1,
        url: c93,
      },
      {
        id: 2,
        url: c92,
      },
      {
        id: 3,
        url: c91,
      },
    ],
    price: 2000,
    title: "Тыквенный кекс c кремом чиз",
    type: "bakery",
    numberOfPieces: "1 шт.",
    description:
      "Тыквенный кекс с клюквой, апельсином, грецким орехом, изюмом, крем чизом, и цельнозерновой муки – это настоящий осенний десерт, который обязательно поднимет настроение в холодную погоду. Сочный и ароматный кекс идеально сочетает в себе сладость тыквы, легкую кислинку клюквы и свежесть апельсина. Грецкий орех и изюм добавляют текстуры и богатства вкуса. Завершающим акцентом этого кулинарного шедевра является крем-чиз. Этот тыквенный кекс станет отличным выбором как для уютного семейного чаепития, так и для праздничного стола. Подавайте его с чашкой горячего какао или ароматного чая, и наслаждайтесь каждым кусочком этого восхитительного десерта!",
  },
  {
    id: 81,
    url: [
      {
        id: 1,
        url: c81,
      },
      {
        id: 2,
        url: c82,
      },
    ],
    price: 500,
    title: "Мини тортик c кокосовой стружкой",
    type: "bakery",
    numberOfPieces: "1 шт.",
    description:
      "Мини тортик из кокоса - это изысканный и нежный десерт, который станет идеальным завершением любого праздника или просто приятным сюрпризом для сладкоежки. Невероятно легкий и воздушный, он обладает неповторимым вкусом и ароматом свежего кокоса, который перенесет вас на райский остров посреди городской суеты.",
  },
  {
    id: 77,
    url: [
      {
        id: 1,
        url: c77,
      },
      {
        id: 2,
        url: c78,
      },
      {
        id: 3,
        url: c79,
      },
      {
        id: 4,
        url: c80,
      },
    ],
    price: 700,
    title: "Тарт с бельгийским шоколадом",
    type: "bakery",
    numberOfPieces: "1 шт.",
    description:
      "Тарта с бельгийским шоколадом - это восхитительный десерт, который непременно удивит и порадует ваши вкусовые рецепторы. Тонкое тесто из нежной песочной крошки покрыто сверху слоем ароматного бельгийского шоколада, создавая гармоничный контраст текстур. Этот тарт - настоящий шедевр шоколадного искусства, где каждый кусочек тает во рту. Бельгийский шоколад, выбранный для этого десерта, обладает глубоким и насыщенным вкусом, идеально сочетающимся с легким и воздушным тестом.",
  },
  {
    id: 83,
    url: [
      {
        id: 1,
        url: c83,
      },
      {
        id: 2,
        url: c84,
      },
    ],
    price: 600,
    title: "Мини сердечки",
    type: "bakery",
    numberOfPieces: "2 шт.",
    description: "",
  },
  {
    id: 85,
    url: [
      {
        id: 1,
        url: c85,
      },
      {
        id: 2,
        url: c86,
      },
    ],
    price: 600,
    title: "Мини сердечки с бельгийским клубничным шоколадом",
    type: "bakery",
    numberOfPieces: "2 шт.",
    description: "",
  },
  {
    id: 88,
    url: [
      {
        id: 1,
        url: c88,
      },
      {
        id: 2,
        url: c87,
      },
    ],
    price: 600,
    title: "Мини сердечки с бельгийским темным шоколадом",
    type: "bakery",
    numberOfPieces: "2 шт.",
    description: "",
  },
  {
    id: 90,
    url: [
      {
        id: 1,
        url: c90,
      },
      {
        id: 2,
        url: c89,
      },
    ],
    price: 600,
    title: "Мини сердечки с бельгийским молочным шоколадом",
    type: "bakery",
    numberOfPieces: "2 шт.",
    description: "",
  },
  {
    id: 70,
    url: [
      {
        id: 1,
        url: c70,
      },
      {
        id: 2,
        url: c69,
      },
      {
        id: 3,
        url: c68,
      },
    ],
    price: 300,
    title: "Мадлен с бельгийским темным шоколадом",
    type: "bakery",
    numberOfPieces: "1 шт.",
    description:
      "Мадлен - это изысканный и нежный десерт, созданный с любовью и вдохновением. Тонкое сочетание вкусов и ароматов делает это печенье неповторимым и запоминающимся. В основе печенья Мадлен лежит бельгийский шоколад и нежное тесто, приготовленное из высококачественной муки, сливочного масла и яиц. Каждый ингредиент играет свою роль в создании идеальной текстуры и вкуса этого десерта. Секрет его мягкости и нежности заключается в том, что тесто для печенья готовится особым образом, благодаря которому оно становится воздушным и легким.",
  },
  {
    id: 72,
    url: [
      {
        id: 1,
        url: c71,
      },
      {
        id: 2,
        url: c72,
      },
      {
        id: 3,
        url: c68,
      },
    ],
    price: 300,
    title: "Мадлен с бельгийским клубничным шоколадом",
    type: "bakery",
    numberOfPieces: "1 шт.",
    description:
      "Мадлен - это изысканный и нежный десерт, созданный с любовью и вдохновением. Тонкое сочетание вкусов и ароматов делает это печенье неповторимым и запоминающимся. В основе печенья Мадлен лежит бельгийский шоколад и нежное тесто, приготовленное из высококачественной муки, сливочного масла и яиц. Каждый ингредиент играет свою роль в создании идеальной текстуры и вкуса этого десерта. Секрет его мягкости и нежности заключается в том, что тесто для печенья готовится особым образом, благодаря которому оно становится воздушным и легким.",
  },
  {
    id: 73,
    url: [
      {
        id: 1,
        url: c73,
      },
      {
        id: 2,
        url: c74,
      },
      {
        id: 3,
        url: c68,
      },
    ],
    price: 300,
    title: "Мадлен с бельгийским молочным шоколадом",
    type: "bakery",
    numberOfPieces: "1 шт.",
    description:
      "Мадлен - это изысканный и нежный десерт, созданный с любовью и вдохновением. Тонкое сочетание вкусов и ароматов делает это печенье неповторимым и запоминающимся. В основе печенья Мадлен лежит бельгийский шоколад и нежное тесто, приготовленное из высококачественной муки, сливочного масла и яиц. Каждый ингредиент играет свою роль в создании идеальной текстуры и вкуса этого десерта. Секрет его мягкости и нежности заключается в том, что тесто для печенья готовится особым образом, благодаря которому оно становится воздушным и легким.",
  },
  {
    id: 75,
    url: [
      {
        id: 1,
        url: c75,
      },
      {
        id: 2,
        url: c76,
      },
      {
        id: 3,
        url: c68,
      },
    ],
    price: 300,
    title: "Мадлен с бельгийским светлым шоколадом",
    type: "bakery",
    numberOfPieces: "1 шт.",
    description:
      "Мадлен - это изысканный и нежный десерт, созданный с любовью и вдохновением. Тонкое сочетание вкусов и ароматов делает это печенье неповторимым и запоминающимся. В основе печенья Мадлен лежит бельгийский шоколад и нежное тесто, приготовленное из высококачественной муки, сливочного масла и яиц. Каждый ингредиент играет свою роль в создании идеальной текстуры и вкуса этого десерта. Секрет его мягкости и нежности заключается в том, что тесто для печенья готовится особым образом, благодаря которому оно становится воздушным и легким.",
  },
  {
    id: 1,
    url: [
      {
        id: 1,
        url: c1,
      },
      {
        id: 2,
        url: c61,
      },
      {
        id: 3,
        url: c13,
      },
      {
        id: 4,
        url: c65,
      },
      {
        id: 5,
        url: c67,
      },
    ],
    price: 700,
    title: "Гата сладкая",
    type: "bakery",
    numberOfPieces: "1шт.",
    description:
      "Потрясающе нежное слоеное печенье, традиционно именуемое Гата, способно насытить на долгое время.\
        Вкуснейшая сладкая начинка, а снаружи хрустящая корочка. Идеален для чаепития в кругу семьи, а также для праздничных мероприятий.",
  },
  {
    id: 62,
    url: [
      {
        id: 1,
        url: c62,
      },
      {
        id: 2,
        url: c65,
      },
      {
        id: 3,
        url: c66,
      },
    ],
    price: 700,
    title: "Гата с солью",
    type: "bakery",
    numberOfPieces: "1шт.",
    description:
      "Потрясающе нежное слоеное печенье, традиционно именуемое Гата, способно насытить на долгое время.\
        Вкуснейшая соленая начинка, а снаружи хрустящая корочка. Идеален для чаепития в кругу семьи, а также для праздничных мероприятий.",
  },
  {
    id: 63,
    url: [
      {
        id: 1,
        url: c64,
      },
      {
        id: 2,
        url: c63,
      },
      {
        id: 3,
        url: c65,
      },
    ],
    price: 700,
    title: "Гата с грецким орехом",
    type: "bakery",
    numberOfPieces: "1шт.",
    description:
      "Потрясающе нежное слоеное печенье, традиционно именуемое Гата, способно насытить на долгое время.\
        Вкуснейшая начинка с грецким орехом, а снаружи хрустящая корочка. Идеален для чаепития в кругу семьи, а также для праздничных мероприятий.",
  },
  {
    id: 52,
    url: [
      {
        id: 1,
        url: c52,
      },
    ],
    price: 5000,
    title: "Гата круглая",
    type: "bakery",
    numberOfPieces: "Целое изделие",
    description:
      "Потрясающе нежное слоеное печенье, традиционно именуемое Гата, способно насытить на долгое время.\
        Вкуснейшая сладкая начинка, а снаружи хрустящая корочка. Идеален для чаепития в кругу семьи, а также для праздничных мероприятий.",
  },
  {
    id: 55,
    url: [
      {
        id: 1,
        url: c55,
      },
    ],
    price: 600,
    title: "Хлеб",
    type: "bakery",
    numberOfPieces: "Упаковка 6шт.",
    description:
      "Воздушный порционный хлеб с хрустящей корочкой. Прекрасен и как самостоятельное блюдо, так и в дополнение к основному блюду. Наш хлеб, выполненный по авторскому, уникальному рецепту прекрасен во всем!",
  },
  {
    id: 54,
    url: [
      {
        id: 1,
        url: c54,
      },
      {
        id: 111112,
        url: c54,
      },
    ],
    price: 600,
    title: "Хлеб с семенами",
    type: "bakery",
    numberOfPieces: "Упаковка 6шт.",
    description:
      "Порционный, авторский хлеб выполненный по уникальному рецепту.",
  },
  {
    id: 2,
    url: [
      {
        id: 1,
        url: c2,
      },
      {
        id: 2,
        url: c14,
      },
    ],
    price: 7000,
    title: "№-013",
    type: "bakery",
    numberOfPieces: "Целое изделие",
    description:
      "Торт выполненный по авторскому и уникальному рецепту. Это крайне сложный и долгий в приготовлении торт, требующий соблюдения особой технологии приготовления. Основу торта составляют слойки из тонких коржей, которые пропитаны наивкуснейшим заварным кремом. Этот торт будет великолепен как в повседневные дни, так и в праздничные;",
  },
  {
    id: 3,
    url: [
      {
        id: 1,
        url: c3,
      },
      {
        id: 2,
        url: c48,
      },
      {
        id: 3,
        url: c20,
      },
    ],
    price: 780,
    title: "Мини-тортики",
    type: "bakery",
    numberOfPieces: "1шт.",
    description:
      "Порционные мини-тортики, выполненные по авторскому, уникальному рецепту. Основу торта составляют бисквитные коржи, пропитанные наивкуснейшим сливочным кремом, и обсыпанные бисквитной крошкой. Удобная форма и размеры порций прекрасно подойдут для легкого перекуса в течение дня).",
  },
  {
    id: 4,
    url: [
      {
        id: 1,
        url: c4,
      },
      {
        id: 5,
        url: c60,
      },
      {
        id: 2,
        url: c5,
      },
      {
        id: 3,
        url: c9,
      },
      {
        id: 4,
        url: c10,
      },
    ],
    price: 600,
    title: "Варенье виноградное с миндалём",
    type: "jem",
    numberOfPieces: "1 шт.",
    description:
      "Авторское виноградное варенье с миндалем! Упаковка - стеклянная банка, стерилизованная, вес НЕТТО 415 г. Состав: виноград, миндаль, лимон, сахар.",
  },
  {
    id: 6,
    url: [
      {
        id: 1,
        url: c6,
      },
      {
        id: 2,
        url: c35,
      },
    ],
    price: 8000,
    title: "№-016",
    type: "bakery",
    numberOfPieces: "Целое изделие.",
    description:
      "Восхитительный торт, выполненный из тонких медовых коржей, пропитанных великолепным нежным заварным кремом. В состав данного изделия входят только высококачественные ингредиенты. Приготовленный по особой технологии, торт украсит любой праздничный стол.",
  },
  {
    id: 7,
    url: [
      {
        id: 1,
        url: c7,
      },
      {
        id: 2,
        url: c8,
      },
    ],
    price: 8000,
    title: "Пахлава",
    type: "bakery",
    numberOfPieces: "Целое изделие.",
    description:
      'Потрясающе вкусная "Пахлава", которая никого не оставит не оставит равнодушным! Выполнено изделие из слоеного теста, а в прослойке из безе добавлены отборные грецкие орехи. Безупречный вкус и эффектная подача выпечки украсит Ваш стол в любой праздник!',
  },
  {
    id: 11,
    url: [
      {
        id: 1,
        url: c11,
      },
      {
        id: 2,
        url: c12,
      },
      {
        id: 3,
        url: c16,
      },
    ],
    price: 2000,
    title: "Тыквенный пирог",
    type: "bakery",
    numberOfPieces: "Целое изделие.",
    description:
      "Нежный и сочный тыквенный пирог с является отличным завершением обеда или ужина, а также прекрасным дополнением к чашечке горячего чая или кофе. Готовый тыквенный пирог имеет аппетитный вид – золотистая корочка теста и нежно-оранжевая начинка из тыквы. Разрезанный на порционные кусочки, он радует глаз своей яркостью и привлекательностью. Вкус десерта гармонично сочетает сладость тыквы и нежность теста. Тыквенный пирог является прекрасным выбором для семейных праздников, дружеских посиделок и уютных домашних вечеров.",
  },
  {
    id: 57,
    url: [
      {
        id: 1,
        url: c57,
      },
      {
        id: 2,
        url: c58,
      },
    ],
    price: 600,
    title: "Варенье тыквенное с грецким орехом",
    type: "jem",
    numberOfPieces: "1 шт.",
    description:
      "Авторское тыквенное варенье с грецким орехом! Упаковка - стеклянная банка, стерилизованная. Состав: тыква, грецкий орех, лимон, сахар.",
  },
  {
    id: 49,
    url: [
      {
        id: 1,
        url: c49,
      },
      {
        id: 2,
        url: c59,
      },
    ],
    price: 800,
    title: "Аджика из болгарского перца.",
    type: "souse",
    numberOfPieces: "1 шт.",
    description:
      "Авторская аджика из болгарского перца! Упаковка - стеклянная банка, стерилизованная, вес НЕТТО 310 г. Состав: красный болгарский перец, оливковое масло, петрушка, чеснок, соль.",
  },
  {
    id: 18,
    url: [
      {
        id: 1,
        url: c18,
      },
      {
        id: 2,
        url: c17,
      },
    ],
    price: 6000,
    title: "Сливочно-слоеный торт",
    type: "bakery",
    numberOfPieces: "Целое изделие.",
    description:
      "Наивкуснейший нежный сливочный слоеный торт, который никого не оставит равнодушным!!! Основу торта составляют тонкие коржи, идеально пропитанные вкуснейшим заварным кремом. Великолепен как в обычные дни, так и в праздничные!",
  },
  {
    id: 19,
    url: [
      {
        id: 1,
        url: c32,
      },
      {
        id: 2,
        url: c31,
      },
      {
        id: 3,
        url: c19,
      },
    ],
    price: 5000,
    title: "Слоеный салат",
    type: "salads",
    numberOfPieces: "Целое изделие.",
    description:
      "Невероятно вкусный и сытный слоеный салат, в котором все ингредиенты идеально сочетаются между собой. Прекрасный вариант как в повседневные дни, так и в праздничные.",
  },
  // {
  //   id: 53,
  //   url:[
  //     {
  //       id:1,
  //       url: c53
  //     }
  //   ],
  //   price: 600,
  //   title: "Пирожки",
  //   type: "bakery",
  //   numberOfPieces: "Упаковка 5шт.",
  //   description:
  //     "Авторские пирожки выполненные по уникальному рецепту! С разными начинками.",
  // },
  {
    id: 21,
    url: [
      {
        id: 1,
        url: c21,
      },
      {
        id: 2,
        url: c34,
      },
    ],
    price: 5000,
    title: "№-022",
    type: "bakery",
    numberOfPieces: "Упаковка 6шт.",
    description:
      "Потрясающие пирожные, выполненные по оригинальному рецепту! Изделия выполнены из песочного теста с шоколадным кремом, красиво украшенные орехами. Очень эффектные пирожные, способные украсить Ваш стол.",
  },
  {
    id: 23,
    url: [
      {
        id: 1,
        url: c23,
      },
      {
        id: 2,
        url: c22,
      },
    ],
    price: 6000,
    title: "№-029",
    type: "bakery",
    numberOfPieces: "Целое изделие.",
    description:
      "Восхитительно нежный, медово-карамельный слоёный торт. Основу торта составляют тонкие карамельные коржи, пропитанные наивкуснейшим сливочным кремом. Великолепен как в обычные дни, так и в праздничные;",
  },
  // {
  //   id: 50,
  //   url:[
  //     {
  //       id:1,
  //       url: c51
  //     },
  //     {
  //       id: 2,
  //       url: c50
  //     }
  //   ],
  //   price: 800,
  //   title: "Пицца",
  //   type: "bakery",
  //   numberOfPieces: "Целое изделие.",
  //   description:
  //     "Авторская пицца! Выполнена по собственному рецепту из самых качественных ингредиентов. Тонкое тесто с хрустящей корочкой, щедро обсыпано тонкими ломтиками колбасы и шапкой из тянущегося сыра. Такая пицца однозначно порадует Ваших близких и гостей!",
  // },
  {
    id: 24,
    url: [
      {
        id: 1,
        url: c24,
      },
      {
        id: 2,
        url: c36,
      },
    ],
    price: 4000,
    title: "№-027",
    type: "salads",
    numberOfPieces: "Целое изделие.",
    description:
      "Невероятно вкусный и сытный слоеный салат, в котором все ингредиенты идеально сочетаются между собой. Прекрасный вариант как в повседневные дни, так и в праздничные.",
  },
  {
    id: 25,
    url: [
      {
        id: 1,
        url: c25,
      },
      {
        id: 2,
        url: c29,
      },
    ],
    price: 7000,
    title: "№-015",
    type: "bakery",
    numberOfPieces: "Целое изделие.",
    description:
      "Потрясающий торт, основу которого составляют тонкие коржи, пропитанные великолепным заварным кремом, политый шоколадной глазурью. Выполненный, по особой технологии, торт украсит любой праздничный стол;",
  },
  // {
  //   id: 26,
  //   url:[
  //     {
  //       id:1,
  //       url: c56
  //     },
  //     {
  //       id:2,
  //       url: c26
  //     }
  //   ],
  //   price: 1500,
  //   title: "№-018",
  //   type: "bakery",
  //   numberOfPieces: "Целое изделие.",
  //   description:
  //     '"Хачапури" с сыром, выполнен по авторскому рецепту! Идеальное соотношение теста и начинки! Прекрасен как в повседневные дни, так и в праздничные!',
  // },
  {
    id: 27,
    url: [
      {
        id: 1,
        url: c27,
      },
      {
        id: 2,
        url: c30,
      },
    ],
    price: 5000,
    title: "№-020",
    type: "bakery",
    numberOfPieces: "Упаковка 6шт.",
    description:
      "вкуснейшим кремом. Очень эффектные пирожные, способные украсить Ваш стол.",
  },
  {
    id: 28,
    url: [
      {
        id: 1,
        url: c33,
      },
    ],
    price: 4500,
    title: "Мини-тортики Ёжики",
    type: "bakery",
    numberOfPieces: "Упаковка 6шт.",
    description:
      "Порционные мини-тортики, выполненные по авторскому, уникальному рецепту. Основу торта составляют бисквитные коржи, пропитанные наивкуснейшим сладким кремом, и обсыпанные бисквитной крошкой. Удобная форма и размеры порций прекрасно подойдут для легкого перекуса, даже на ходу ).",
  },
  {
    id: 29,
    url: [
      {
        id: 1,
        url: c15,
      },
      {
        id: 2,
        url: c47,
      },
    ],
    price: 7000,
    title: "Арахисовый торт",
    type: "bakery",
    numberOfPieces: "Целое изделие.",
    description:
      "Изумительный арахисовый торт, который способен насытить и наполнить энергией на долгое время. Слоеный торт состоит из бисквитных коржей, хрустящих кусочков арахиса, промазанных невероятно вкусным кремом. А прослойка из безе делает этот торт воздушным.",
  },
  {
    id: 30,
    url: [
      {
        id: 1,
        url: c39,
      },
      {
        id: 2,
        url: c38,
      },
      {
        id: 3,
        url: c37,
      },
    ],
    price: 4500,
    title: "№-031",
    type: "salads",
    numberOfPieces: "Целое изделие.",
    description:
      "Невероятно вкусный и сытный салат, в котором все ингредиенты идеально сочетаются между собой. Прекрасный вариант как в повседневные дни, так и в праздничные.",
  },
  {
    id: 31,
    url: [
      {
        id: 1,
        url: c40,
      },
      {
        id: 2,
        url: c41,
      },
      {
        id: 3,
        url: c42,
      },
      {
        id: 4,
        url: c43,
      },
    ],
    price: 5000,
    title: "№-030",
    type: "salads",
    numberOfPieces: "Целое изделие.",
    description:
      "Невероятно вкусный и сытный салат, в котором все ингредиенты идеально сочетаются между собой. Прекрасный вариант как в повседневные дни, так и в праздничные.",
  },
  {
    id: 32,
    url: [
      {
        id: 1,
        url: c44,
      },
      {
        id: 2,
        url: c45,
      },
      {
        id: 3,
        url: c46,
      },
    ],
    price: 5000,
    title: "Миндальный торт",
    type: "bakery",
    numberOfPieces: "Целое изделие.",
    description:
      "Восхитительный миндальный торт, приготовлен из высококачественной миндальной муки. Бисквитные коржи покрыты нежнейшим кремом, а весь торт щедро обсыпан хрустящей миндальной стружкой. Идеален в качестве десерта, как в повседневные дни, так и в праздничные.",
  },
];

export default card;
